var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('PageTitle',{attrs:{"items":{
      title: 'コラム',
      subTitle: 'タグ管理',
      buttons: [
        {
          title: '新規作成',
          icon: 'mdi-plus-circle',
          action: function () {
            _vm.launchNew = true
          }
        }
      ]
    }}}),_c('DataTable',{attrs:{"items":_vm.getAllMediaTags,"headers":_vm.headers,"total-records":_vm.totalRecords ? _vm.totalRecords : 0,"number-of-pages":_vm.totalPages ? _vm.totalPages : 0},on:{"click:row":function($event){_vm.launchEdit = true
      _vm.editItem = $event}},scopedSlots:_vm._u([{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInitiate(item.id)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$delete")])],1)]}}])}),_c('MediaTagDialog',{attrs:{"launch":_vm.launchNew,"edit":false},on:{"update:launch":function($event){_vm.launchNew=$event},"refresh":_vm.getDataFromApi}}),_c('MediaTagDialog',{attrs:{"launch":_vm.launchEdit,"item":_vm.editItem},on:{"update:launch":function($event){_vm.launchEdit=$event},"update":_vm.update}}),_c('SimpleModel',{attrs:{"text":"このタグを削除してよろしいですか？","dialog":_vm.dialog.delete,"submitButtonText":'削除する'},on:{"submitSuccess":function($event){return _vm.deleteMediaTags()},"closeModel":function($event){_vm.dialog.delete = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }